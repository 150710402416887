import { useSearchParams } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';

const Search = ({ type = 'text', className, text, onSearch, value }) => {
	const [startDate, history] = useSearchParams();

	return (
		<div className={"custom-search-input " + className}>
			<input
				type={type}
				onChange={e => {
					let search = e.target.value.toString()
					onSearch(e.target.value);
					history({search: search}, { replace: true })
				}}
				placeholder={text}
				value={value} />
			<SearchOutlined />
		</div>
	);
};

export default Search;
